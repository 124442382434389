import React from "react";
import "styles/global.css";
import "styles/rulebricks.scss";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import CookieBanner from "components/CookieBanner";
import "util/analytics";
import { AuthProvider } from "util/auth";
import { QueryClientProvider } from "util/db";
import { useRouter } from "next/router";
import { Archivo, Source_Code_Pro, Lora } from "next/font/google";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import { withPasswordProtect } from "next-password-protect";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import { BugAntIcon } from "@heroicons/react/24/solid";
import { IconBug, IconViewfinder } from "@tabler/icons-react";

const archivo = Archivo({
  subsets: ["latin"],
  variable: "--font-archivo",
  fallback: ["Archivo", "system-ui"],
});

const lora = Lora({
  subsets: ["latin"],
  variable: "--font-lora",
  fallback: ["Lora", "system-ui"],
});

const sourceCodePro = Source_Code_Pro({
  subsets: ["latin"],
  variable: "--font-source-code-pro",
  fallback: ["Source Code Pro", "Courier", "monospace"],
});

var testingErrorBoundary = false;
var highlightInitialized = false;

function App({ Component, pageProps }) {
  const router = useRouter();

  const showNavbar = () => {
    const { pathname } = router;
    const path = pathname.split("/")[1];
    return !["api", "dashboard", "settings", "form"].includes(path);
  };

  if (typeof window !== "undefined") {
    const { pathname } = router;
    if (pathname.includes("dashboard") && !highlightInitialized) {
      if (process.env.NODE_ENV != "development" || testingErrorBoundary) {
        H.init("qe9yw4g1", {
          environment: process.env.ENVIRONMENT
            ? process.env.ENVIRONMENT
            : process.env.NODE_ENV,
          networkRecording: {
            recordHeadersAndBody: true,
          },
        });
        highlightInitialized = true;
      }
    }
  }

  return (
    <QueryClientProvider>
      <AuthProvider>
        <ErrorBoundary
          showDialog={
            process.env.NODE_ENV != "development" || testingErrorBoundary
          }
          dialogOptions={{ hideHighlightBranding: true }}
          customDialog={
            <div
              className={`${archivo.variable} w-screen h-screen font-sans flex flex-col ${lora.variable} ${sourceCodePro.variable}`}
            >
              <div className="w-auto h-full justify-center align-middle flex flex-col text-left bg-black">
                <div className="max-w-3xl self-center h-auto flex flex-col space-y-3 -mt-8 px-4">
                  <img
                    className="w-64 h-64 mb-16 self-center"
                    src="/static/images/error.png"
                    alt="Rulebricks Error Logo"
                    type="image/svg+xml"
                  />
                  <div className="w-full font-light  text-left mb-4 border-t border-red-400/50 pt-8">
                    <h1 className="text-2xl text-white mb-1.5">
                      Application Error
                    </h1>
                    <p className="text-base text-neutral-300 pr-8">
                      We're sorry you've encountered an issue working with
                      Rulebricks– if you're seeing this, we're already working
                      on a fix, so you won't have to see this again. <br />
                      <br />
                      <i>
                        To help us unblock you sooner, please describe the exact
                        action that caused this page to appear. You can also
                        reload this page to skip error reporting & try resuming
                        your work.
                      </i>
                    </p>
                  </div>
                  <form className="w-full space-y-5">
                    <div className="w-full">
                      <div className="mt-2">
                        <textarea
                          id="context"
                          name="context"
                          rows={3}
                          className="bg-neutral-800 text-white placeholder:text-neutral-400 focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-base border-none border-neutral-900 rounded-none"
                          placeholder="Tried to click the 'Create Flow' button..."
                        ></textarea>
                      </div>
                    </div>
                    <div className="w-full flex-row-reverse flex pt-4">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-3 border border-transparent text-base font-medium rounded-none text-white bg-red-600 hover:bg-red-400 duration-300 focus:outline-none focus-within:outline-none focus-within:ring-none"
                      >
                        <IconViewfinder className="w-4 h-full mr-2.5 " />
                        Submit Report
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
        >
          <main
            className={`${archivo.variable} font-sans flex flex-col ${lora.variable} ${sourceCodePro.variable}`}
          >
            <>
              {/* showNavbar() ? <Banner /> : <></> */}
              {showNavbar() ? <Navbar /> : <></>}

              <Component {...pageProps} />
              {showNavbar() ? <CookieBanner /> : <></>}
              {showNavbar() ? <Footer /> : <></>}
            </>
          </main>
        </ErrorBoundary>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default ["staging"].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)
  ? withPasswordProtect(App, {
      // Options go here (optional)
      loginApiUrl: "/api/staging-login",
      checkApiUrl: "/api/staging-password-check",
      loginComponentProps: {
        buttonColor: "#FFFFFF",
        buttonBackgroundColor: "#000000",
        logo: "/static/images/logo.png",
      },
    })
  : App;
