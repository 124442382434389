import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";
import { nanoid } from "nanoid";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that fetches data
    () =>
      supabase
        .from("users")
        .select(`*, customers ( * ), usage ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid },
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * ), usage ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}

/**** RULES ****/

// Fetch item data
export function useRule(id) {
  return useQuery(
    ["rule", { id }],
    () => supabase.from("rules").select().eq("id", id).single().then(handle),
    { enabled: !!id },
  );
}

export function getRule(id) {
  return supabase.from("rules").select().eq("id", id).single().then(handle);
}

export async function deleteTemplate(userId, templates, delTemplateIdx) {
  // remove template at index
  let newTemplates = [...templates];
  newTemplates.splice(delTemplateIdx, 1);
  // update user templates
  const response = supabase
    .from("users")
    .update({ templates: newTemplates })
    .eq("id", userId)
    .then(handle);
  await client.invalidateQueries(["user", { uid: userId }]);
  return response;
}

// Fetch all rules by owner
export function useRulesByOwner(owner) {
  return useQuery(
    ["rules", { owner }],
    () =>
      supabase
        .from("rules")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner },
  );
}

export function useRulePreviewsByOwner(owner) {
  return useQuery(
    ["rule-previews", { owner }],
    () =>
      supabase
        .from("rules")
        .select(
          "id, owner, name, description, createdAt, updatedAt, requestSchema, responseSchema, sampleRequest, sampleResponse, updatedBy, slug, published, publishedAt, no_conditions, groups, accessGroups, form, history, settings",
        )
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner },
  );
}

// Create a new item
export async function createRule(data) {
  const response = await supabase
    .from("rules")
    .insert([data])
    .select()
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["rules"]);
  await client.invalidateQueries(["rule-previews"]);
  return response;
}

// Duplicate an item
export async function duplicateRule(id) {
  const response = await supabase
    .from("rules")
    .select()
    .eq("id", id)
    .single()
    .then(({ data }) => {
      delete data.id;
      return supabase
        .from("rules")
        .insert([
          {
            ...data,
            name: `${data.name} (Copy)`,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            slug: nanoid(10),
            published: false,
            publishedAt: null,
            published_requestSchema: null,
            published_responseSchema: null,
            published_conditions: null,
            published_groups: null,
            history: [],
          },
        ])
        .single()
        .then(handle);
    });
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["rules"]);
  await client.invalidateQueries(["rule-previews"]);
  return response;
}

// Update an item
export async function updateRule(id, data) {
  const response = await supabase
    .from("rules")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["rule", { id }]),
    client.invalidateQueries(["rules"]),
    client.invalidateQueries(["rule-previews"]),
  ]);
  return response;
}

// Delete an item
export async function deleteRule(id) {
  const response = await supabase
    .from("rules")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["rule", { id }]),
    client.invalidateQueries(["rules"]),
    client.invalidateQueries(["rule-previews"]),
  ]);
  return response;
}

/**** FLOWS ****/

// Fetch item data
export function useFlow(id) {
  return useQuery(
    ["flow", { id }],
    () => supabase.from("flows").select().eq("id", id).single().then(handle),
    { enabled: !!id },
  );
}

// Fetch all rules by owner
export function useFlowsByOwner(owner) {
  return useQuery(
    ["flows", { owner }],
    () =>
      supabase
        .from("flows")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner },
  );
}

export function useFlowPreviewsByOwner(owner) {
  return useQuery(
    ["flow-previews", { owner }],
    () =>
      supabase
        .from("flows")
        .select(
          "id, owner, name, description, createdAt, updatedAt, updatedBy, origin, slug, published, publishedAt, settings, accessGroups",
        )
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner },
  );
}

// Create a new item
export async function createFlow(data) {
  const response = await supabase
    .from("flows")
    .insert([data])
    .select()
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["flows"]);
  await client.invalidateQueries(["flow-previews"]);
  return response;
}

// Duplicate an item
export async function duplicateFlow(id) {
  const response = await supabase
    .from("flows")
    .select()
    .eq("id", id)
    .single()
    .then(({ data }) => {
      delete data.id;
      return supabase
        .from("flows")
        .insert([
          {
            ...data,
            name: `${data.name} (Copy)`,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            slug: nanoid(10),
            published: false,
            publishedAt: null,
            published_graph: null,
            history: [],
          },
        ])
        .single()
        .then(handle);
    });
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["flows"]);
  await client.invalidateQueries(["flow-previews"]);
  return response;
}

// Update an item
export async function updateFlow(id, data) {
  const response = await supabase
    .from("flows")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["flow", { id }]),
    client.invalidateQueries(["flows"]),
    client.invalidateQueries(["flow-previews"]),
  ]);
  return response;
}

// Delete an item
export async function deleteFlow(id) {
  const response = await supabase
    .from("flows")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["flow", { id }]),
    client.invalidateQueries(["flows"]),
    client.invalidateQueries(["flow-previews"]),
  ]);
  return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) throw response.error;
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
