import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

import Link from "next/link";
import { useAuth } from "util/auth";
import { useRouter } from "next/router";
import Image from "next/image";

let navigation = [
  { name: "Use Cases", href: "/examples", key: "examples" },
  { name: "Pricing", href: "/pricing", key: "pricing" },
  {
    name: "User Guide",
    href: "https://rulebricks.com/docs",
    target: "_blank",
    key: "user-guide",
  },
  { name: "Blog", href: "/blog", key: "blog" },
];

if (process.env.NEXT_PUBLIC_SELF_HOSTED) {
  navigation = [
    {
      name: "User Guide",
      href: "https://rulebricks.com/docs",
      target: "_blank",
      key: "user-guide",
    },
  ];
}

export default function Navbar() {
  const auth = useAuth();
  const router = useRouter();

  return (
    <div className="relative overflow-hidden bg-transparent select-none z-50">
      <div className="relative py-5">
        <Popover>
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex flex-1 items-center text-xl">
              <div className="flex w-full items-center justify-between md:w-auto">
                <a
                  href={
                    !process.env.NEXT_PUBLIC_SELF_HOSTED ? "/" : "/dashboard"
                  }
                >
                  <div className="inline-flex cursor-pointer mt-1.5">
                    <Image
                      className="w-8 ml-1 sm:ml-2 mr-4 h-full self-center"
                      width={32}
                      height={32}
                      src="/static/images/logo.png"
                      alt="Rulebricks Logo"
                    />
                    <span className="text-2xl font-semibold">Rulebricks</span>
                  </div>
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="focus-ring-inset md:hidden inline-flex items-center justify-center rounded-md bg-white m-2 text-black hover:text-gray-800 focus:outline-none focus:ring-none">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden whitespace-nowrap space-x-8 md:ml-16 md:flex">
                {navigation.map((item) => (
                  <Link legacyBehavior key={item.key} href={item.href}>
                    <a
                      data-instant
                      className={
                        "truncate " +
                        (router.pathname.includes(item.href)
                          ? "underline underline-offset-2"
                          : "hover:underline underline-offset-2")
                      }
                      target={item.target}
                    >
                      {item.name}
                    </a>
                  </Link>
                ))}
              </div>
              <div className="hidden md:flex-1 md:flex md:items-center md:justify-end">
                {auth.user && (
                  <>
                    <Link legacyBehavior href="/dashboard">
                      <a
                        className={
                          router.pathname.includes("/dashboard")
                            ? "underline underline-offset-2 ml-10"
                            : "hover:underline underline-offset-2 ml-10"
                        }
                      >
                        Dashboard
                      </a>
                    </Link>
                    {/*
              <Link legacyBehavior href="/settings/general">
                <a className={router.pathname.includes("/settings") ? "underline underline-offset-2 ml-5" : "hover:underline underline-offset-2 ml-5"}>Settings</a>
              </Link>
              */}
                    <Link legacyBehavior href="/auth/signout">
                      <a
                        className="ml-8 whitespace-nowrap hover:text-red-700 "
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                        }}
                      >
                        Sign out
                      </a>
                    </Link>
                  </>
                )}

                {!auth.user && (
                  <Link legacyBehavior href="/auth/signin">
                    <a
                      className={
                        (router.pathname.includes("signin")
                          ? "underline ml-5"
                          : "ml-5") +
                        " hover:underline underline-offset-2 inline-flex h-full"
                      }
                    >
                      <span
                        className={
                          (router.pathname.includes("signin")
                            ? "underline underline-offset-2 ml-5"
                            : "ml-5") +
                          " hover:underline underline-offset-2 inline-flex h-full"
                        }
                      >
                        Sign in
                      </span>
                      <ArrowRightIcon className="inline-flex h-full w-6 ml-2 align-middle self-center" />
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="fixed inset-x-0 top-0 z-50 origin-top-right transform p-2 transition md:hidden"
            >
              {({ close }) => (
                <div className="overflow-hidden rounded-sm shadow-xl bg-white ring-1 ring-black ring-opacity-100">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <a
                        className="w-auto outline-none border-none focus-within:ring-0 focus-within:border-none"
                        onClick={close}
                        href={
                          !process.env.NEXT_PUBLIC_SELF_HOSTED
                            ? "/"
                            : "/dashboard"
                        }
                      >
                        <Image
                          className="w-10 mr-4 h-full self-center"
                          width={32}
                          height={32}
                          src="/static/images/logo.png"
                          alt="Rulebricks Logo"
                        />
                      </a>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded bg-white p-1.5 text-black focus:outline-none focus:ring-none">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-5 pb-8">
                    {navigation.map((item) => (
                      <Link legacyBehavior key={item.key} href={item.href}>
                        <a
                          onClick={close}
                          className="block px-3 py-2 text-2xl font-medium text-black underline underline-offset-4"
                        >
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </div>
                  {!auth.user && (
                    <Link legacyBehavior href="/auth/signin">
                      <a
                        onClick={close}
                        className={
                          "block w-full bg-black px-5 py-5 text-2xl text-left font-medium text-white focus:bg-gray-800" +
                          (router.pathname.includes("/auth/signin")
                            ? "underline underline-offset-2 ml-5"
                            : "ml-5") +
                          "inline-flex"
                        }
                      >
                        Sign in
                      </a>
                    </Link>
                  )}
                  {auth.user && (
                    <>
                      <Link legacyBehavior href="/dashboard">
                        <a
                          onClick={close}
                          className={
                            "block w-full bg-black px-5 py-5 text-2xl text-left font-medium text-white focus:bg-gray-800" +
                            (router.pathname.includes("/dashboard")
                              ? "underline underline-offset-2"
                              : "hover:underline underline-offset-2")
                          }
                        >
                          Dashboard
                        </a>
                      </Link>
                      {/*
                <Link legacyBehavior href="/settings/general">
                  <a className={router.pathname.includes("/settings") ? "underline underline-offset-2 ml-5" : "hover:underline underline-offset-2 ml-5"}>Settings</a>
                </Link>
                */}
                      <Link legacyBehavior href="/auth/signout">
                        <a
                          data-no-instant
                          className={
                            "block w-full bg-red-800 bg-opacity-90 px-5 py-5 text-2xl text-left font-medium text-white focus:bg-gray-800" +
                            (router.pathname.includes("/dashboard")
                              ? "underline underline-offset-2"
                              : "hover:underline underline-offset-2")
                          }
                          onClick={(e) => {
                            close();
                            e.preventDefault();
                            auth.signout();
                          }}
                        >
                          Sign out
                        </a>
                      </Link>
                    </>
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
}
