import { ServerStackIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const USER_CONSENT_COOKIE_KEY = "rb_cookie_consent";
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365;

export default function CookieBanner() {
  const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true);

  useEffect(() => {
    const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === "true";
    setCookieConsentIsTrue(consentIsTrue);
  }, []);

  const onClick = (e) => {
    e.preventDefault();

    if (!cookieConsentIsTrue) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, "true", {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
    }
  };

  if (cookieConsentIsTrue) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
      <div className="sm:mx-auto my-1 md:my-0 max-w-[19.5rem] sm:max-w-2xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-md bg-neutral-900 p-[0.435rem] sm:mb-0 mb-1.5 shadow-xl sm:p-2.5">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="hidden md:flex rounded-md bg-neutral-800 p-2 md:ml-1">
                <ServerStackIcon
                  className="h-4 w-4 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">
                  This website collects cookies.
                </span>
                <span className="hidden md:inline">
                  This website may collect cookies to optimize your user
                  experience.
                </span>
              </p>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                onClick={onClick}
                type="button"
                className="flex rounded-md p-2 hover:bg-neutral-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
