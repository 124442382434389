import React from "react";

function PageLoader(props) {
  return (
    <div>
      {!props.children && (
        <div className="text-center text-2xl">
          <div className="center-body">
            <div className="loader-square-27">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}

      {props.children && <>{props.children}</>}
    </div>
  );
}

export default PageLoader;
